import React from 'react';
import styles from './SquareButton.module.css';

interface SquareButtonProps {
    text: string;
    onClick?: (() => void) | ((event: React.FormEvent) => Promise<void>);
    textColor?: string;
    borderColor?: string;
    backgroundColor?: string;
    hoverTextColor?: string;
    hoverBackgroundColor?: string;
    fontFamily?: string;
}

const SquareButton: React.FC<SquareButtonProps> = ({
    text,
    onClick,
    textColor = '#DBFF80',
    borderColor = '#DBFF80',
    backgroundColor = 'transparent',
    hoverTextColor = '#003600',
    hoverBackgroundColor = '#DBFF80',
    fontFamily = "Work Sans, sans-serif",
}) => {
    return (
        <button
            className={styles['link-button']}
            style={
                {
                    '--border-color': borderColor,
                    '--background-color': backgroundColor,
                    '--hover-bg-color': hoverBackgroundColor,
                    '--hover-text-color': hoverTextColor,
                    '--text-color': textColor,
                    fontFamily,
                } as React.CSSProperties
            }
            onClick={onClick}
        >
            {text}
        </button>
    );
};

export default SquareButton;
