// LinkButton/index.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SquareLinkButton.module.css';

interface SquareLinkButtonProps {
    to: string;
    text: string;
    textColor?: string;
    borderColor?: string;
    backgroundColor?: string;
    hoverTextColor?: string;
    hoverBackgroundColor?: string;
    fontFamily?: string;
    onClick?: () => void; // Add the optional onClick prop
};

const SquareLinkButton: React.FC<SquareLinkButtonProps> = ({
    to,
    text,
    textColor = '#DBFF80',
    borderColor = '#DBFF80',
    backgroundColor = 'transparent',
    hoverTextColor = '#003600',
    hoverBackgroundColor = '#DBFF80',
    fontFamily = "'Roboto', sans-serif",
    onClick,
}) => {
    return (
        <Link
            to={to}
            className={styles['link-button']}
            onClick={onClick} // Apply onClick here
            style={
                {
                    '--border-color': borderColor,
                    '--background-color': backgroundColor,
                    '--hover-bg-color': hoverBackgroundColor,
                    '--hover-text-color': hoverTextColor,
                    '--text-color': textColor,
                    fontFamily,
                } as React.CSSProperties
            }
        >
            {text}
        </Link>
    );
};

export default SquareLinkButton;
