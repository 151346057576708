import React from 'react';
import styles from './ImageBox.module.css';

interface ImageBoxProps {
    desktopImageUrl: string;
    mobileImageUrl: string;
    children?: React.ReactNode;
}

const ImageBox: React.FC<ImageBoxProps> = ({ desktopImageUrl, mobileImageUrl, children }) => {
    return (
        <div className={styles.imageBox}>
        {/* Absolutely positioned container for the responsive image */}
            <div className={styles.imageContainer}>
                <picture>
                    <source 
                        media="(max-width: 768px)" 
                        srcSet={mobileImageUrl} 
                    />
                    <source 
                        media="(min-width: 769px)" 
                        srcSet={desktopImageUrl} 
                    />
                    <img 
                        src={desktopImageUrl} 
                        alt=""
                        className={styles.image}
                    />
        </picture>
        </div>
            {children && (
                <div className={styles.content}>
                {children}
                </div>
            )}
        </div>
    );
};

export default ImageBox;
