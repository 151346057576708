import React from 'react';
import styles from './TextBox.module.css';

interface TextBoxProps {
    backgroundColor?: string;
    textColor?: string;
    children?: React.ReactNode;
}

const TextBox: React.FC<TextBoxProps> = ({ backgroundColor, textColor, children }) => {
    const style = {
        backgroundColor: backgroundColor || 'transparent',
        color: textColor || 'inherit',
    };

    return (
        <div className={styles.textBox} style={style}>
            <div className={styles.textContent}>
                {children}
            </div>
        </div>
    );
};

export default TextBox;
