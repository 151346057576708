import React from 'react';
import styles from './IdentityCard.module.css';
import EmailHeader from '../EmailHeader';

interface IdentityCardProps {
    imageSrc: string;
    name: string;
    titleOne: string;
    titleTwo: string;
    email: string;
}

const IdentityCard: React.FC<IdentityCardProps> = ({ 
    imageSrc, 
    name, 
    titleOne,
    titleTwo, 
    email
}) => {
    return (
        <div className={styles.identityCard}>
        <div className={styles.imageContainer}>
            <img 
                src={imageSrc} 
                alt={`${name}'s profile picture`}
                className={styles.profileImage}
            />
        </div>
        <div className={styles.contactInfo}>
            <h3 className={styles.name}>
                {name}
            </h3>
            <h3 className={styles.name}>
                {titleOne}
            </h3>
            <h3 className={styles.name}>
                {titleTwo}
            </h3>
            <EmailHeader
                email={email}
            />
        </div>
    </div>
    );
};

export default IdentityCard;
