import React from "react";
import styles from './FilledSquareButton.module.css';

interface FilledButtonProps {
    text: string;
    backgroundColor?: string;
    textColor?: string;
    hoverTextColor?: string;
    hoverBackgroundColor?: string;
    fontFamily?: string;
    onClick?: () => void;
    disabled?: boolean;
}

const FilledButton: React.FC<FilledButtonProps> = ({
    text, 
    backgroundColor = '#DBFF80',
    textColor = '#003600',
    hoverBackgroundColor = '#003600',
    hoverTextColor = '#DBFF80',
    fontFamily = "'Roboto', sans-serif",
    onClick,
    disabled,
}) => {
    return (
        <button
            className={styles['filled-button']}
            style={
                {
                    '--background-color': backgroundColor,
                    '--text-color': textColor,
                    '--font-family': fontFamily,
                    '--hover-bg-color': hoverBackgroundColor,
                    '--hover-text-color': hoverTextColor,
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    opacity: disabled ? 0.6 : 1,
                } as React.CSSProperties
            }
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </button>
    );
};

export default FilledButton;