import React from 'react';
import styles from './Icon.module.css';

interface IconProps {
    src: string;
    alt?: string;
    width?: string | number;
    height?: string | number;
    link?: string;
}

const Icon: React.FC<IconProps> = ({
    src,
    alt = '',
    width = 24,
    height = 24,
    link,
}) => {
return (
    <a href={link} target="_blank" rel="noopener noreferrer">
        <img
            src={src}
            alt={alt}
            className={styles.icon}
            width={width}
            height={height}
        />
    </a>

);
};

export default Icon;
