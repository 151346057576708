import styles from './Merch.module.css';


const Merch: React.FC = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.merchHeader}>
                Merch
            </h1>
            <p className={styles.merchDescription}>
                Show off your TurtlTracker support seasonally with our small yet
                statement-making winter line. Proceeds support the launch of
                TurtlTracker. Sweatshirts embroidered by a local business in
                Plymouth, MN.
            </p>
        </div>

    );
};

export default Merch;