import axios from 'axios';

const uri = "https://turtl-tracker-web-api-dev-a9dkgparaab6ahez.canadacentral-01.azurewebsites.net/"

export const sendPutRequest = async(path: string, bodyContent: any): Promise<any> => {
    const url = uri + path;
    console.log('PUT base url:', url); 

    try {
        const resp = await axios.put(url, bodyContent, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'text/plain'
            },
        })
        return resp;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const sendPostRequest = async(path: string, bodyContent: any): Promise<any> => {
    const url = uri + path;
    console.log('POST base url:', url);
    console.log('body content', bodyContent);

    try {
        const resp = await axios.post(url, bodyContent, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'text/plain'
            },
        })
        console.log('POST response', JSON.stringify(resp));
        return resp;
    } catch (error) {
        console.error('Error', error);
        throw error;
    }
};