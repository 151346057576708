import React from "react";
import { Link } from 'react-router-dom';
import styles from './SimpleLink.module.css';

interface SimpleLinkProps {
    to: string;
    text: string;
    onClick?: () => void;
}

const SimpleLink: React.FC<SimpleLinkProps> = ({ to, text, onClick }) => (
    <Link
        to={to}
        className={styles['simple-link']}
        onClick={onClick}
    >
        {text}
    </Link>
);

export default SimpleLink;
