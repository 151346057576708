// HomePage.tsx
import React from 'react';
import Row from '../../components/Row';
import ImageBox from '../../components/ImageBox';
import TextBox from '../../components/TextBox';
import styles from './HomePage.module.css';

import ChloeDesktop from './assets/chloe-desktop.jpg';
import ChloeMobile from './assets/chloe-mobile.jpg';
import AppDemoDesktop from './assets/app-demo-desktop.jpg';
import AppDemoMobile from './assets/app-demo-mobile.jpg';

const HomePage: React.FC = () => {
    return (
        <div className={styles.homePage}>
            {/* First Row */}
            <Row rowHeight='80vh'>
                <ImageBox desktopImageUrl={ChloeDesktop} mobileImageUrl={ChloeMobile} />
                <TextBox backgroundColor="#DBFF80" textColor="#003600">
                    Turtle road mortality is an urgent issue. We provide tools and data that {' '}
                    <span 
                        className={styles.underline}
                    >
                        empower everyone to play a part
                    </span>
                    {' '} in addressing this critical threat to turtle populations. 
                </TextBox>
            </Row>

            {/* Middle Row */}
            <Row rowHeight='70vh'>
                <TextBox backgroundColor="#FAFAF2" textColor="#003600">
                    TurtlTracker features AI-centric technology
                    and in-depth education tools so that any user
                    can confidently contribute accurate data and {' '} 
                    <span 
                        className={styles.underline}
                    >
                        help combat turtle road mortality.
                    </span>
                </TextBox>
            </Row>

            {/* Third Row - apply the reverseOnMobile class */}
            <Row rowHeight='80vh' reverseOnMobile={true}>
                <TextBox backgroundColor="#DBFF80" textColor="#003600">
                    Join us in uncovering movement, migration, and mortality trends to pave the way for a turtle-friendly future.{' '}
                    <span 
                        className={styles.underline}
                    >
                        Our private beta will
                        launch spring of 2025.
                    </span>
                </TextBox>
                <ImageBox desktopImageUrl={AppDemoDesktop} mobileImageUrl={AppDemoMobile} />
            </Row>
        </div>
    );
};

export default HomePage;
