import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleLink from '../SimpleLink';
import SquareLinkButton from '../SquareLinkButton';
import styles from './Header.module.css';
import TurtlLogo from './assets/TurtlLogo.png';

const Header: React.FC = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    }

    return (
        <header className={styles.header}>
            <div className={styles['header-container']}>
                <Link to="/" className={styles['logo-container']}>
                    <img
                        src={TurtlLogo}
                        alt="TurtlTracker Logo"
                        className={styles.logo}
                    />
                </Link>
                {/* Hamburger button always visible on mobile */}
                <button
                    className={styles.hamburger}
                    onClick={toggleMobileMenu}
                    aria-label="Toggle navigation"
                >
                    <span className={styles['hamburger-line']}></span>
                    <span className={styles['hamburger-line']}></span>
                    <span className={styles['hamburger-line']}></span>
                </button>

                <nav className={styles['nav-container']}>
                    <div className={styles['simple-links']}>
                        <SimpleLink to="/about-us" text="About us" />
                        <SimpleLink to="/how-to-help" text="How to Help" />
                        {/* <SimpleLink to="merch" text="Merch"/> */}
                        <SimpleLink to="/contact" text="Contact" />
                    </div>
                    <div className={styles['button-container']}>
                        <SquareLinkButton 
                            to="/turtltracker"
                            text="Get TurtlTracker"
                        />
                    </div>
                </nav>

                {isMobileMenuOpen && (
                    <nav className={styles['nav-open']}>
                        {/* Hamburger button inside nav-open, top right */}
                        <button
                            className={styles.hamburger}
                            onClick={toggleMobileMenu}
                            aria-label="Close navigation"
                        >
                            <span className={styles['hamburger-line']}></span>
                            <span className={styles['hamburger-line']}></span>
                            <span className={styles['hamburger-line']}></span>
                        </button>

                        <SimpleLink to="/about-us" text="About us" onClick={toggleMobileMenu} />
                        <SimpleLink to="/how-to-help" text="How to Help" onClick={toggleMobileMenu} />
                        {/* <SimpleLink to="/merch" text="Merch" onClick={toggleMobileMenu} /> */}
                        <SimpleLink to="/contact" text="Contact" onClick={toggleMobileMenu} />

                        <div className={styles['button-container']}>
                            <SquareLinkButton 
                                to="/turtltracker"
                                text="Get TurtlTracker"
                                onClick={toggleMobileMenu}
                            />
                        </div>
                    </nav>
                )}
            </div>
        </header>
    );
};

export default Header;
