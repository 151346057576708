import React from 'react';
import styles from './TextInput.module.css';

interface TextInputProps {
    placeholder?: string;
    borderColor?: string;
    textColor?: string;
    backgroundColor?: string;
    fontFamily?: string;
    className?: string;
    style?: React.CSSProperties;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
}

const TextInput: React.FC<TextInputProps> = ({
    placeholder = '',
    borderColor = '#DBFF80',
    textColor = '#FAFAF2',
    backgroundColor = 'transparent',
    fontFamily = "'Roboto', sans-serif",
    className,
    style,
    value, 
    onChange,
    ...rest
}) => {
    return (
        <input
            type="text"
            className={className}
            placeholder={placeholder}
            style={
                {
                    '--border-color': borderColor,
                    '--text-color': textColor,
                    '--background-color': backgroundColor,
                    '--font-family': fontFamily,
                    ...style,
                } as React.CSSProperties
            }
            onChange={onChange}
            {...rest}
        />
    )
};

export default TextInput;