import React from 'react';
import styles from './CircularButton.module.css';

// TODO: PICKUP 12.11.2024: INTEGRATE CIRCULAR BUTTON INTO HOW TO HELP PAGE
// PER LATEST COPY AND FINSIH HOW TO HELP PAGE

interface CircularButtonProps {
    label: string;
    backgroundColor: string;
    textColor: string;
    selected?: boolean;
    selectedBackgroundColor?: string;
    selectedTextColor?: string;
    onClick: () => void;
}

const CircularButton: React.FC<CircularButtonProps> = ({
    label,
    backgroundColor,
    textColor, 
    selected = false, 
    selectedBackgroundColor = '#DBFF80',
    selectedTextColor = '#003600',
    onClick,
}) => {
    const appliedBackgroundColor = selected ? selectedBackgroundColor : backgroundColor;
    const appliedTextColor = selected ? selectedTextColor : textColor;

    return (
        <button
            className={styles.circularButton}
            style={{ backgroundColor: appliedBackgroundColor, color: appliedTextColor}}
            onClick={onClick}
            aria-pressed={selected}
        >
            {label}
        </button>
    )
};

export default CircularButton;