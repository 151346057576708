import React from 'react';
import styles from './IconButton.module.css';
import { IconType } from 'react-icons';

interface IconButtonProps { 
    iconSrc: IconType;
    iconAlt?: string;
    text: string;
    backgroundColor?: string;
    textColor?: string;
    hoverTextColor?: string;
    hoverBackgroundColor?: string; 
    fontFamily?: string;
    onClick?: () => void;
    className?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
    iconSrc: IconComponent,
    iconAlt = '',
    text, 
    backgroundColor = '#003600',
    textColor = '#DBFF80',
    hoverTextColor = '#DBFF80',
    hoverBackgroundColor = '#003600',
    fontFamily = "'Work Sans', sans-serif",
    onClick,
    className,
}) => {
    return (
    <button
        className={`${styles['icon-button']} ${className || ''}`.trim()}
        style={
            {
                '--background-color': backgroundColor,
                '--text-color': textColor,
                '--hover-text-color': hoverTextColor,
                '--hover-bg-color': hoverBackgroundColor,
                '--font-family': fontFamily,
            } as React.CSSProperties
        }
        onClick={onClick}
    >
        <IconComponent className={styles['icon']} aria-label={iconAlt} />
        <span>
            {text}
        </span>
    </button>
    );
};

export default IconButton;
