import React from 'react';
import styles from './Contact.module.css';
import EmailHeader from '../../components/EmailHeader';
import IdentityCard from '../../components/IdentityCard'; 
import KT from './assets/kt.png';
import QK from './assets/qk.png';

const Contact: React.FC = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.header}>
                Contact
            </h1>
            <div className={styles.emailContainer}>
                <p className={styles.inquiryDescription}>
                    For press/media + general inquiries:
                </p>
                <EmailHeader
                    email='inquiries@turtltracker.com'
                    subject='Media Inquiry'
                    size='small'
                />
            </div>
            <h1 className={styles.header}>
                Leadership
            </h1>
            <IdentityCard
                imageSrc={QK}
                name="Kelly Olson"
                titleOne='Founder'
                titleTwo='CEO / CCO'
                email="kelly@turtltracker.com"
            />
            <IdentityCard
                imageSrc={KT}
                name="Katie O'Halloran"
                titleOne='Founder'
                titleTwo='CTO / CFO'
                email='katie@turtltracker.com'
            />
        </div>
    );
};

export default Contact;