import React from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styles from './PaymentForm.module.css';
import SquareButton from '../SquareButton';

interface PaymentFormProps {
    amount: number;
    clientSecret: string;
    onSuccess?: () => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ amount, clientSecret, onSuccess }) => {
    const stripe = useStripe();
    const elements = useElements();


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!stripe || !elements) return;
        
        elements.submit();
        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
              return_url: window.location.origin // required by TS types
            },
            redirect: 'if_required'
        });

        if (paymentIntent && paymentIntent.status === 'succeeded') {
            if (onSuccess) onSuccess();
            alert("Your payment was successful! Thank you for supporting TurtlTracker!");
        } else if (error) {
            alert('Payment failed! Please try again later');
        }
    };

    return (
        <form className={styles.paymentForm}>
            <PaymentElement />
            <div className={styles.donateButtonContainer}>
                <SquareButton
                    text={`Confirm Donation of $${amount}`}
                    onClick={handleSubmit}
                    textColor="#DBFF80"
                    borderColor='#DBFF80'
                    backgroundColor='#003600'
                    hoverTextColor='#003600'
                    hoverBackgroundColor='#DBFF80'
                />
            </div>
        </form>
    );
};

/* TODO: PICKUP 12.4.2024: INTEGRATE PAYMENT FORM INTO HOW TO HELP PAGE WITH DONATION
        VISUALS. DEPLOY API AND TEST WITH BACKEND AFTER BANK ACCOUNT SET UP WHICH NEEDS TO 
        BE INTEGRATED INTO STRIPE!!
*/
export default PaymentForm;