import React from 'react';
import styles from './Row.module.css';

interface RowProps {
    rowHeight?: string;
    children: React.ReactNode;
    reverseOnMobile?: boolean; // Add this prop
}

const Row: React.FC<RowProps> = ({ children, rowHeight = '60vh', reverseOnMobile = false }) => {
    const childrenArray = React.Children.toArray(children);

    const rowStyle: React.CSSProperties = {
        height: rowHeight
    };

    // Conditionally apply the reverse class based on the prop
    const rowClassName = `${styles.row} ${reverseOnMobile ? styles.reverseOnMobile : ''}`;

    return (
        <div className={rowClassName} style={rowStyle}>
            {childrenArray.map((child, index) => (
                <div key={index} className={styles.cell}>
                    {child}
                </div>
            ))}
        </div>
    );
};

export default Row;
