import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/Home';
import AboutUs from './pages/AboutUs';
import HowToHelp from './pages/HowToHelp';
import Merch from './pages/Merch';
import Contact from './pages/Contact';
import TurtlTracker from './pages/TurtlTracker';
import styles from './App.module.css';
import { Helmet } from 'react-helmet';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <title>TurtlTracker</title>
        <link rel="icon" type="image/png" href="/Turtle-Icon-DarkGreen.png" sizes="16x16" />
      </Helmet>
      <div className={styles['page-container']}>
        <Header />
        <div className={styles['content-wrap']}>
          <main className="content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/how-to-help" element={<HowToHelp />} />
              <Route path="merch" element={<Merch />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/turtltracker" element={<TurtlTracker />} />
              <Route path="/about-us" element={<AboutUs />} />
            </Routes>
          </main>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
