import React from 'react';
import styles from './AboutUs.module.css';

const AboutUs: React.FC = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.aboutHeader}>
                Can AI help save turtles?
            </h1>
            <p className={styles.aboutUsDescription}>
                Here at TurtlTracker, we certainly
                think so.
            </p>
            <p className={styles.aboutUsDescription}>
                Over 60% of turtle species are
                endangered or threatened, and
                road mortality is a significant
                contributor. TurtlTracker is a
                scalable app and social platform
                powered by AI, enabling anyone
                with a phone and a heart for the
                environment to contribute
                accurate data.
            </p>
            <p className={styles.aboutUsDescription}>
                By mapping the true impact of
                road mortality, we aim to
                advocate for turtle crossings
                where they’re needed most, and
                help users adopt a small
                behavior (See a turtle, track a
                turtle!) to speed up conservation
                efforts for this, well... very slow
                species. 
            </p>
            <p className={styles.aboutUsDescription}>
                TurtlTracker also includes tools
                to educate users on how to best
                assist a turtle crossing the road,
                as well as a robust social
                platform to connect and engage
                with other users.
            </p>
            <p className={styles.aboutUsDescriptionAccented}>
                We will kick off our efforts with
                a private beta launch in
                Minnesota in the spring of 2025,
                and hope to expand nationwide
                in the near future. Happy
                tracking!
            </p>
        </div>

    );
};

export default AboutUs;